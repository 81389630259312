import React , { useState, useEffect } from "react";
import usdt from "../../assets/usdt.png";
import { Link } from "react-router-dom";
import {totalrevnew,totalteamsize1,totalrevnew_slot2,Total_slot_revenue_slot2} from "../../Actions";
import Web3 from "web3";
import {contractAddress,abi,  X3contract, X3abi} from "../../connectivity/api";
import {useSelector ,useDispatch } from "react-redux";



const X3 = () => {
  const dispatch = useDispatch();
  const myState = useSelector((state) => state.Usermatamaskaddress);
  

  const [renewslot1, setrenewslot1] = useState(0);
  const [renewslot2, setrenewslot2] = useState(0);
  const [renewslot3, setrenewslot3] = useState(0);
  const [renewslot4, setrenewslot4] = useState(0);
  const [renewslot5, setrenewslot5] = useState(0);
  const [renewslot6, setrenewslot6] = useState(0);


  const [amount, setamount] = useState("");
  const [amountslot2, setamountslot2] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [addresses_slot2, setAddresses_slot2] = useState([]);
  const [addresses_slot3, setAddresses_slot3] = useState([]);
  const [addresses_slot4, setAddresses_slot4] = useState([]);
  const [addresses_slot5, setAddresses_slot5] = useState([]);
  const [addresses_slot6, setAddresses_slot6] = useState([]);

  const [member, setmember] = useState('0');
  const [memberslot2, setmemberslot2] = useState('0');
  const [memberslot3, setmemberslot3] = useState('0');
  const [memberslot4, setmemberslot4] = useState('0');
  const [memberslot5, setmemberslot5] = useState('0');
  const [memberslot6, setmemberslot6] = useState('0');

 
  const userinfo = async () => {
    try {
      let intex1;
      let a;
      let a33
      let aa ;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let X_3contract = new window.web3.eth.Contract(X3abi, X3contract);
            
      if (myState.address) {
        a = await contract.methods.Users_3x(myState.address).call();
        a33 = await X_3contract.methods.Users_3X(myState.address).call();
        let activeid = await contract.methods.check_useractive_id_List_3x(myState.address).call();
      
        aa = await contract.methods.Pool_check_Direct_3x(parseInt(activeid[parseInt(a. index)])).call();
        
        for(let w=0 ; w< activeid.length ; w++){
          let team = await contract.methods.User_Referral_3x(parseInt(activeid[w])).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        formattedAddresses[0] = parseInt(a.firstid)
         intex1 = parseInt(aa.Id1);
        let intex2 = parseInt(aa.Id2);
        let intex3 = parseInt(aa.Id3);
        let index1address;
        let index2address;
        let index3address;
        if(intex1 > 0){
          index1address = await contract.methods.Pool_check_Direct_3x(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
            let q = await contract.methods.Pool_check_Direct_3x(parseInt(index1address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[13] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[14] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[15] = parseInt(q.Id3)
            }
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
            let q = await contract.methods.Pool_check_Direct_3x(parseInt(index1address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[16] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[17] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[18] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
            let q = await contract.methods.Pool_check_Direct_3x(parseInt(index1address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[19] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[20] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[21] = parseInt(q.Id3)
            }                
          }
        }
        if(intex2 > 0){
          index2address = await contract.methods.Pool_check_Direct_3x(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
            let q = await contract.methods.Pool_check_Direct_3x(parseInt(index2address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[22] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[23] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[24] = parseInt(q.Id3)
            }               
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
            let q = await contract.methods.Pool_check_Direct_3x(parseInt(index2address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[25] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[26] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[27] = parseInt(q.Id3)
            }             
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
            let q = await contract.methods.Pool_check_Direct_3x(parseInt(index2address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[28] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[29] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[30] = parseInt(q.Id3)
            }             
          }          
        }
        if(intex3 > 0){
          index3address = await contract.methods.Pool_check_Direct_3x(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
            let q = await contract.methods.Pool_check_Direct_3x(parseInt(index3address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[31] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[32] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[33] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
            let q = await contract.methods.Pool_check_Direct_3x(parseInt(index3address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[34] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[35] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[36] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
            let q = await contract.methods.Pool_check_Direct_3x(parseInt(index3address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[37] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[38] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[39] = parseInt(q.Id3)
            }             
          }            
        }   
       let totalrenew = parseInt(parseInt(totalteam1)/ 39) ;
       setmember(parseInt(totalteam1));
       setrenewslot1(totalrenew);
       setamount((window.web3.utils.fromWei((parseInt(a.Earn) + parseInt(a33.Earn)  ), "ether")))
       dispatch(totalrevnew(window.web3.utils.fromWei(parseInt(a.Totalslotrevenue), "ether")));
      }
      setAddresses(formattedAddresses);
      
    } catch (error) {
      console.log("Registration Error slot1:", error);
    }
  };

  const userinfo_slot2 = async () => {
    try {
      let intex1;
      let a;
      let aa ;
      let formattedAddresses = [];
      let totalteam1 =0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let X_3contract = new window.web3.eth.Contract(X3abi, X3contract);
      if (myState.address) { 
        a = await X_3contract.methods.Users_3X(myState.address).call();
        let activeid = await X_3contract.methods.check_useractive_id_List(myState.address,2).call();
        for(let w=0 ; w< activeid.length ; w++){
          let team = await X_3contract.methods.User_Referral(parseInt(a.firstid_slot2)).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        aa = await X_3contract.methods.Pool_check_Direct(parseInt(a.firstid_slot2)).call(); 
        formattedAddresses[0] = parseInt(a.firstid_slot2)
         intex1 = parseInt(aa.Id1);
        let intex2 = parseInt(aa.Id2);
        let intex3 = parseInt(aa.Id3);
        let index1address;
        let index2address;
        let index3address;

        if(intex1 > 0){
          index1address = await X_3contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[13] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[14] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[15] = parseInt(q.Id3)
            }
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[16] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[17] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[18] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[19] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[20] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[21] = parseInt(q.Id3)
            }                
          }
        }
        if(intex2 > 0){
          index2address = await X_3contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[22] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[23] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[24] = parseInt(q.Id3)
            }               
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[25] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[26] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[27] = parseInt(q.Id3)
            }             
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[28] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[29] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[30] = parseInt(q.Id3)
            }             
          }          
        }
        if(intex3 > 0){
          index3address = await X_3contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[31] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[32] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[33] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[34] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[35] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[36] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[37] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[38] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[39] = parseInt(q.Id3)
            }             
          }            
        }   
       let totalrenew = parseInt(parseInt(totalteam1)/ 39) ;
       setmemberslot2(parseInt(totalteam1));
       setrenewslot2(totalrenew);
       setamountslot2(window.web3.utils.fromWei(parseInt(a.Totalslotrevenue), "ether"))
      }
      setAddresses_slot2(formattedAddresses);     
    } catch (error) {
      console.log("Registration Error slot2:", error);
    }
  };  

  const userinfo_slot3 = async () => {
    try {
      let intex1;
      let a;
      let aa ;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let X_3contract = new window.web3.eth.Contract(X3abi, X3contract);
      if (myState.address) {
        a = await X_3contract.methods.Users_3X1(myState.address).call();
        let activeid = await X_3contract.methods.check_useractive_id_List(myState.address,3).call();
        for(let w=0 ; w< activeid.length ; w++){
          let team = await X_3contract.methods.User_Referral(parseInt(a.firstid_slot3)).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        aa = await X_3contract.methods.Pool_check_Direct(parseInt(a.firstid_slot3)).call(); 
        formattedAddresses[0] = parseInt(a.firstid_slot3)
         intex1 = parseInt(aa.Id1);
        let intex2 = parseInt(aa.Id2);
        let intex3 = parseInt(aa.Id3);
        let index1address;
        let index2address;
        let index3address;

        if(intex1 > 0){
          index1address = await X_3contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[13] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[14] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[15] = parseInt(q.Id3)
            }
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[16] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[17] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[18] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[19] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[20] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[21] = parseInt(q.Id3)
            }                
          }
        }
        if(intex2 > 0){
          index2address = await X_3contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[22] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[23] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[24] = parseInt(q.Id3)
            }               
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[25] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[26] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[27] = parseInt(q.Id3)
            }             
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[28] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[29] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[30] = parseInt(q.Id3)
            }             
          }          
        }
        if(intex3 > 0){
          index3address = await X_3contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[31] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[32] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[33] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[34] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[35] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[36] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[37] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[38] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[39] = parseInt(q.Id3)
            }             
          }            
        }   
       let totalrenew = parseInt(parseInt(totalteam1)/ 39) ;
       setmemberslot3(parseInt(totalteam1));
       setrenewslot3(totalrenew);
      }
      setAddresses_slot3(formattedAddresses);
      console.log("rrrr3" ,formattedAddresses )
    } catch (error) {
      console.log("Registration Error slot3:", error);
    }
  }; 

  const userinfo_slot4 = async () => {
    try {
      let intex1;
      let a;
      let aa ;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let X_3contract = new window.web3.eth.Contract(X3abi, X3contract);
      if (myState.address) {
        a = await X_3contract.methods.Users_3X1(myState.address).call();
        let activeid = await X_3contract.methods.check_useractive_id_List(myState.address,4).call();
        for(let w=0 ; w< activeid.length ; w++){
          let team = await X_3contract.methods.User_Referral(parseInt(a.firstid_slot4)).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        console.log("ttt" , a.firstid_slot4)
        aa = await X_3contract.methods.Pool_check_Direct(parseInt(a.firstid_slot4)).call(); 
        formattedAddresses[0] = parseInt(a.firstid_slot4)
         intex1 = parseInt(aa.Id1);
        let intex2 = parseInt(aa.Id2);
        let intex3 = parseInt(aa.Id3);
        let index1address;
        let index2address;
        let index3address;

        if(intex1 > 0){
          index1address = await X_3contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[13] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[14] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[15] = parseInt(q.Id3)
            }
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[16] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[17] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[18] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[19] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[20] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[21] = parseInt(q.Id3)
            }                
          }
        }
        if(intex2 > 0){
          index2address = await X_3contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[22] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[23] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[24] = parseInt(q.Id3)
            }               
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[25] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[26] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[27] = parseInt(q.Id3)
            }             
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[28] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[29] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[30] = parseInt(q.Id3)
            }             
          }          
        }
        if(intex3 > 0){
          index3address = await X_3contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[31] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[32] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[33] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[34] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[35] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[36] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[37] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[38] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[39] = parseInt(q.Id3)
            }             
          }            
        }   
       let totalrenew = parseInt(parseInt(totalteam1)/ 39) ;
       setmemberslot4(parseInt(totalteam1));
       setrenewslot4(totalrenew);
      }
      setAddresses_slot4(formattedAddresses);
      console.log("rrrr4" ,formattedAddresses )
    } catch (error) {
      console.log("Registration Error slot4:", error);
    }
  }; 

  const userinfo_slot5 = async () => {
    try {
      let intex1;
      let a;
      let aa ;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let X_3contract = new window.web3.eth.Contract(X3abi, X3contract);
      if (myState.address) {
        a = await X_3contract.methods.Users_3X1(myState.address).call();
        let activeid = await X_3contract.methods.check_useractive_id_List(myState.address,5).call();
        for(let w=0 ; w< activeid.length ; w++){
          let team = await X_3contract.methods.User_Referral(parseInt(a.firstid_slot5)).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        aa = await X_3contract.methods.Pool_check_Direct(parseInt(a.firstid_slot5)).call(); 
        formattedAddresses[0] = parseInt(a.firstid_slot5)
         intex1 = parseInt(aa.Id1);
        let intex2 = parseInt(aa.Id2);
        let intex3 = parseInt(aa.Id3);
        let index1address;
        let index2address;
        let index3address;

        if(intex1 > 0){
          index1address = await X_3contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[13] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[14] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[15] = parseInt(q.Id3)
            }
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[16] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[17] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[18] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[19] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[20] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[21] = parseInt(q.Id3)
            }                
          }
        }
        if(intex2 > 0){
          index2address = await X_3contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[22] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[23] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[24] = parseInt(q.Id3)
            }               
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[25] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[26] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[27] = parseInt(q.Id3)
            }             
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[28] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[29] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[30] = parseInt(q.Id3)
            }             
          }          
        }
        if(intex3 > 0){
          index3address = await X_3contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[31] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[32] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[33] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[34] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[35] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[36] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[37] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[38] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[39] = parseInt(q.Id3)
            }             
          }            
        }   
       let totalrenew = parseInt(parseInt(totalteam1)/ 39) ;
       setmemberslot5(parseInt(totalteam1));
       setrenewslot5(totalrenew);
      }
      setAddresses_slot5(formattedAddresses);
      console.log("rrrr5" ,formattedAddresses )
    } catch (error) {
      console.log("Registration Error slot5:", error);
    }
  }; 


  const userinfo_slot6 = async () => {
    try {
      let intex1;
      let a;
      let aa ;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let X_3contract = new window.web3.eth.Contract(X3abi, X3contract);
      if (myState.address) {
        a = await X_3contract.methods.Users_3X1(myState.address).call();
        let activeid = await X_3contract.methods.check_useractive_id_List(myState.address,6).call();
        for(let w=0 ; w< activeid.length ; w++){
          let team = await X_3contract.methods.User_Referral(parseInt(a.firstid_slot6)).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        aa = await X_3contract.methods.Pool_check_Direct(parseInt(a.firstid_slot6)).call(); 
        formattedAddresses[0] = parseInt(a.firstid_slot6)
         intex1 = parseInt(aa.Id1);
        let intex2 = parseInt(aa.Id2);
        let intex3 = parseInt(aa.Id3);
        let index1address;
        let index2address;
        let index3address;

        if(intex1 > 0){
          index1address = await X_3contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[13] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[14] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[15] = parseInt(q.Id3)
            }
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[16] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[17] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[18] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[19] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[20] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[21] = parseInt(q.Id3)
            }                
          }
        }
        if(intex2 > 0){
          index2address = await X_3contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[22] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[23] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[24] = parseInt(q.Id3)
            }               
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[25] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[26] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[27] = parseInt(q.Id3)
            }             
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[28] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[29] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[30] = parseInt(q.Id3)
            }             
          }          
        }
        if(intex3 > 0){
          index3address = await X_3contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id1)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[31] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[32] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[33] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id2)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[34] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[35] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[36] = parseInt(q.Id3)
            }            
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
            let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id3)).call();
            if( parseInt(q.Id1) > 0 ){
              formattedAddresses[37] = parseInt(q.Id1)
            }
            if( parseInt(q.Id2) > 0 ){
              formattedAddresses[38] = parseInt(q.Id2)
            }
            if( parseInt(q.Id3) > 0 ){
              formattedAddresses[39] = parseInt(q.Id3)
            }             
          }            
        }   
       let totalrenew = parseInt(parseInt(totalteam1)/ 39) ;
       setmemberslot6(parseInt(totalteam1));
       setrenewslot6(totalrenew);
      }
      setAddresses_slot6(formattedAddresses);
      console.log("rrrr6" ,formattedAddresses )
    } catch (error) {
      console.log("Registration Error slot6:", error);
    }
  }; 



  useEffect(() => {
    userinfo();
    userinfo_slot2();
    userinfo_slot3();
    userinfo_slot4();
    userinfo_slot5();
    userinfo_slot6();
  }, [myState.address]);

  return (
    <div>
      <div className="page-wrapper" id="home">
        <div className="page-content pb-5">
          <div className="row">
            <div className="contentt">
              <span>Phoenix 15X</span>
              <span>{amount} USDT</span>
            </div>
          </div>
          <div className="row pb-1">
            <div className="col-lg-12 col-xl-12 pb-2 ">
              <Link to="/Dashboard/X3Slot1">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot1</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>5</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  class={
                                    addresses[0]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                ></div>
                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                    addresses[1]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses[4]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[13]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[14]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[15]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses[5]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[16]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[17]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[18]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses[6]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[19]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[20]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[21]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                    addresses[2]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses[7]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[22]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[23]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[24]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses[8]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[25]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[26]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[27]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses[9]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[28]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[29]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[30]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                    addresses[3]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses[10]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[31]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[32]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[33]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses[11]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[34]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[35]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[36]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses[12]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[37]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[38]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses[39]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>



                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{member}</b>
                        </div>
                        <div>{renewslot1}
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            
            <div className="col-lg-12 col-xl-12 pb-2 ">
              <Link to="/Dashboard/X3Slot2">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot2</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>10</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  class={
                                    addresses_slot2[0]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                ></div>
                              </h2>
                            </a>
                          </div>

                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                    addresses_slot2[1]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses_slot2[4]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[13]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[14]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[15]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses_slot2[5]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[16]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[17]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[18]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses_slot2[6]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[19]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[20]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[21]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                    addresses_slot2[2]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses_slot2[7]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[22]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[23]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[24]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses_slot2[8]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[25]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[26]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[27]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses_slot2[9]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[28]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[29]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[30]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      class={
                                    addresses_slot2[3]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses_slot2[10]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[31]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[32]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[33]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses_slot2[11]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[34]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[35]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[36]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          class={
                                    addresses_slot2[12]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[37]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[38]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="popover__wrapper">
                                        <a>
                                          <h2 class="popover__title">
                                            <div
                                              _ngcontent-cgm-c25=""
                                              class={
                                    addresses_slot2[39]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                            ></div>
                                          </h2>
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{memberslot2}</b>
                        </div>
                        <div>
                          {renewslot2}<svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="row pb-1">
            <div className="col-lg-12 col-xl-12 pb-2 ">
              <Link to="/Dashboard/X3Slot3">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot3</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>20</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    addresses_slot3[0]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                  
                                ></div>
                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot3[1]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[4]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[13]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[14]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[15]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[5]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[16]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[17]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[18]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[6]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[19]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[20]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[21]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot3[2]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[7]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[22]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[23]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[24]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[8]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[25]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[26]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[27]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[9]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[28]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[29]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[30]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot3[3]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[10]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[31]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[32]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[33]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[11]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[24]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[35]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[36]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[12]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[37]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[38]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot3[39]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{memberslot3}</b>
                        </div>
                        <div>{renewslot3}
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-12 col-xl-12 pb-2 ">
              <Link to="/Dashboard/X3Slot4">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot4</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>40</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  
                                  className={
                                    addresses_slot4[0]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                ></div>
                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot4[1]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[4]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[13]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[14]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[15]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[5]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[16]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[17]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[18]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[6]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[19]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[20]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[21]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot4[2]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[7]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[22]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[23]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[24]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[8]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[25]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[26]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[27]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[9]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[28]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[29]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[30]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot4[3]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[10]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[31]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[32]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[33]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[11]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[34]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[35]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[36]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[12]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[37]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[38]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot4[39]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{memberslot4}</b>
                        </div>
                        <div>{renewslot4}
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="row pb-1">
            <div className="col-lg-12 col-xl-12 pb-2 ">
              <Link to="/Dashboard/X3Slot5">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot5</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>80</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  
                                  className={
                                    addresses_slot5[0]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                ></div>
                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot5[1]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[4]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[13]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[14]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[15]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[5]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[16]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[17]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[18]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[6]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[19]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[20]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[21]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot5[2]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[7]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[22]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[23]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[24]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[8]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[25]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[26]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[27]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[9]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[28]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[29]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[30]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot5[3]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[10]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[31]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[32]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[33]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[11]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[34]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[35]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[36]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[12]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[37]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[38]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot5[39]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{memberslot5}</b>
                        </div>
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>



            <div className="col-lg-12 col-xl-12 pb-2 ">
              <Link to="/Dashboard/X3Slot6">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot6</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>160</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    addresses_slot6[0]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                ></div>
                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot6[1]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[4]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[13]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[14]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[15]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[5]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[16]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[17]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[18]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[6]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[19]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[20]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[21]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot6[2]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[7]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[22]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[23]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[24]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[8]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[25]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[26]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[27]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[9]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[28]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[29]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[30]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                    addresses_slot6[3]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[10]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[31]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[32]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[33]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[11]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[34]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[35]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[36]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[12]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                  <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[37]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[38]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                    addresses_slot6[39]
                                         ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                         : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{memberslot6}</b>
                        </div>
                        <div>{renewslot6}
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default X3;
