import React, { useState, useEffect } from "react";
import "./Tree_View.css";
import usdt from "../../assets/usdt.png";
import {MainID,totalteamsize_slot6,Total_slot_revenue_slot6,totalrevnew_slot6,totalteamsize_slot5,Total_slot_revenue_slot5,totalrevnew_slot5,totalteamsize_slot4,Total_slot_revenue_slot4,totalrevnew_slot4,Total_slot_revenue_slot3,totalrevnew_slot3,totalteamsize_slot3,totalrevnew,totalteamsize1,totalrevnew_slot2,Total_slot_revenue_slot2} from "../../Actions";
import Web3 from "web3";
import {contractAddress,abi} from "../../connectivity/api";
import {useSelector ,useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/app.min.css";
import "../../assets/bootstrap.min.css";
// import "../../assets/icons.min.css";

const Tree_View = () => {
  const dispatch = useDispatch();
  const myState = useSelector((state) => state.Usermatamaskaddress);
  let myteam = useSelector((state) => state.totalteamsize);  
  let User_MainID = useSelector((state) => state.MainID); 
  // let myteam1 = useSelector((state) => state.totalteamsize1);
  
  const [userteam, setuserteam] = useState(0);
  const [renewslot1, setrenewslot1] = useState(0);
  const [renewslot2, setrenewslot2] = useState(0);
  const [renewslot3, setrenewslot3] = useState(0);
  const [renewslot4, setrenewslot4] = useState(0);
  const [renewslot5, setrenewslot5] = useState(0);
  const [renewslot6, setrenewslot6] = useState(0);
  const [amount, setamount] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [addresses1, setAddresses1] = useState([]);
  const [addresses2, setAddresses2] = useState([]);
  const [addresses3, setAddresses3] = useState([]);
  const [addresses4, setAddresses4] = useState([]);
  const [addresses5, setAddresses5] = useState([]);
  const [memberslot2, setmemberslot2] = useState('');
  const [memberslot3, setmemberslot3] = useState('');
  const [memberslot4, setmemberslot4] = useState('');
  const [memberslot5, setmemberslot5] = useState('');
  const [memberslot6, setmemberslot6] = useState('');
  
  
  const userinfo = async () => {
    try {
      console.log("User_MainID" ,User_MainID)
      let intex1;
      let totalslotrevenue;
      let a;
      let aa ;
      let formattedAddresses = [];
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (myState.address) {
        a = await contract.methods.Users(myState.address).call();
        setuserteam(parseInt(myteam.teamsize));
        let activeid = await contract.methods.check_useractive_id_List(myState.address,1).call();
        let indexx = parseInt(activeid.length - 1)
        aa = await contract.methods.Pool_check_Direct(parseInt(activeid[indexx])).call();
        formattedAddresses[0] = parseInt(a.firstid)
         intex1 = parseInt(aa.Id1);
        let intex2 = parseInt(aa.Id2);
        let intex3 = parseInt(aa.Id3);
        let index1address;
        let index2address;
        let index3address;
        if(intex1 > 0){
          index1address = await contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
          }
        }
        if(intex2 > 0){
          index2address = await contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
          }          
        }
        if(intex3 > 0){
          index3address = await contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
          }            
        }                
       let totalrenew = parseInt(parseInt(parseInt(myteam.teamsize)) / 12);
       setrenewslot1(totalrenew);
       dispatch(totalrevnew(window.web3.utils.fromWei(parseInt(a.Totalslotrevenue), "ether")));
      }
      setAddresses(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot1:", error);
    }
  };

  const userinfo1 = async () => {
    try {
      let a;
      let aa ;
      let intex1;
      let teamslot2 = 0 ;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (myState.address) {
        a = await contract.methods.Users(myState.address).call();
        
        teamslot2 = parseInt([parseInt(a.team)]) + parseInt(teamslot2)
        let activeid = await contract.methods.check_useractive_id_List(myState.address,2).call();
        if(activeid.length > 0){
        for(let w=0 ; w< activeid.length ; w++){
          let team = await contract.methods.User_Referral(parseInt(activeid[w])).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        activeid = parseInt(activeid[parseInt(a.index_slot2)])
        aa = await contract.methods.Pool_check_Direct(parseInt(activeid)).call();
        formattedAddresses[0] = parseInt(a.firstid_slot2)
         intex1 = parseInt(aa.Id1);
        let intex2 = parseInt(aa.Id2);
        let intex3 = parseInt(aa.Id3);
        let index1address;
        let index2address;
        let index3address;
        if(intex1 > 0){
          index1address = await contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
          }
        }
        if(intex2 > 0){
          index2address = await contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
          }
        }
        if(intex3 > 0){
          index3address = await contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
          }         
        }                
        setmemberslot2(parseInt(totalteam1))
        let totalrenew = parseInt(parseInt(totalteam1) / 12);
        setrenewslot2(totalrenew);
        dispatch(totalteamsize1(parseInt(totalteam1)));
        dispatch(totalrevnew_slot2(totalrenew)); 
        dispatch(Total_slot_revenue_slot2(window.web3.utils.fromWei(parseInt(a.Totalslotrevenue), "ether"))); 
      }
    }
      setAddresses1(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot2:", error);
    }
  }; 

  const userinfo_Slot3 = async () => {
    try {
      let a;
      let aa ;
      let intex1;
      let aab = 0 ;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (myState.address) {
        aab = await contract.methods.Users(myState.address).call();
        a = await contract.methods.Users1(myState.address).call();
        let activeid = await contract.methods.check_useractive_id_List(myState.address,3).call();
        for(let w=0 ; w< activeid.length ; w++){
          let team = await contract.methods.User_Referral(parseInt(activeid[w])).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        activeid = parseInt(activeid[parseInt(a.index_slot3)])
        let intex2;
        let intex3;
        if(activeid > 0){
          aa = await contract.methods.Pool_check_Direct(parseInt(activeid)).call();
        formattedAddresses[0] = parseInt(activeid)
         intex1 = parseInt(aa.Id1);
         intex2 = parseInt(aa.Id2);
         intex3 = parseInt(aa.Id3);
        }
        let index1address;
        let index2address;
        let index3address;

        if(intex1 > 0){
          index1address = await contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
          }
        }
        if(intex2 > 0){
          index2address = await contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
          }
        }
        if(intex3 > 0){
          index3address = await contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
          }         
        }                

        setmemberslot3(parseInt(totalteam1))
        let totalrenew = parseInt(parseInt(totalteam1) / 12);
        setrenewslot3(totalrenew);
        dispatch(totalteamsize_slot3(parseInt(totalteam1)));
        dispatch(totalrevnew_slot3(totalrenew)); 
        dispatch(Total_slot_revenue_slot3(window.web3.utils.fromWei(parseInt(aab.Totalslotrevenue), "ether"))); 

      }
      setAddresses2(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot3:", error);
    }
  };   

  const userinfo_Slot4 = async () => {
    try {
      let a;
      let aa ;
      let intex1;
      let aab = 0 ;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (myState.address) {
        aab = await contract.methods.Users(myState.address).call();
        a = await contract.methods.Users1(myState.address).call();
        let activeid = await contract.methods.check_useractive_id_List(myState.address,4).call();
        for(let w=0 ; w< activeid.length ; w++){
          let team = await contract.methods.User_Referral(parseInt(activeid[w])).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        activeid = parseInt(activeid[parseInt(a.index_slot4)])
        let intex2;
        let intex3;
        if(activeid > 0){
          aa = await contract.methods.Pool_check_Direct(parseInt(activeid)).call();
        formattedAddresses[0] = parseInt(activeid)
         intex1 = parseInt(aa.Id1);
         intex2 = parseInt(aa.Id2);
         intex3 = parseInt(aa.Id3);
        }
        let index1address;
        let index2address;
        let index3address;

        if(intex1 > 0){
          index1address = await contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
          }
        }
        if(intex2 > 0){
          index2address = await contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
          }
        }
        if(intex3 > 0){
          index3address = await contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
          }         
        }                

        setmemberslot4(parseInt(totalteam1))
        let totalrenew = parseInt(parseInt(totalteam1) / 12);
        setrenewslot4(totalrenew);
        dispatch(totalteamsize_slot4(parseInt(totalteam1)));
        dispatch(totalrevnew_slot4(totalrenew)); 
        dispatch(Total_slot_revenue_slot4(window.web3.utils.fromWei(parseInt(aab.Totalslotrevenue), "ether"))); 
      }
      setAddresses3(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot3:", error);
    }
  };  
  
  const userinfo_Slot5 = async () => {
    try {
      let a;
      let aa ;
      let intex1;
      let aab = 0 ;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (myState.address) {
        aab = await contract.methods.Users(myState.address).call();
        a = await contract.methods.Users1(myState.address).call();
        let activeid = await contract.methods.check_useractive_id_List(myState.address,5).call();
        for(let w=0 ; w< activeid.length ; w++){
          let team = await contract.methods.User_Referral(parseInt(activeid[w])).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        activeid = parseInt(activeid[parseInt(a.index_slot5)])
        let intex2;
        let intex3;
        if(activeid > 0){
          aa = await contract.methods.Pool_check_Direct(parseInt(activeid)).call();
        formattedAddresses[0] = parseInt(activeid)
         intex1 = parseInt(aa.Id1);
         intex2 = parseInt(aa.Id2);
         intex3 = parseInt(aa.Id3);
        }
        let index1address;
        let index2address;
        let index3address;

        if(intex1 > 0){
          index1address = await contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
          }
        }
        if(intex2 > 0){
          index2address = await contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
          }
        }
        if(intex3 > 0){
          index3address = await contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
          }         
        }                

        setmemberslot5(parseInt(totalteam1))
        let totalrenew = parseInt(parseInt(totalteam1) / 12);
        setrenewslot5(totalrenew);
        dispatch(totalteamsize_slot5(parseInt(totalteam1)));
        dispatch(totalrevnew_slot5(totalrenew)); 
        dispatch(Total_slot_revenue_slot5(window.web3.utils.fromWei(parseInt(aab.Totalslotrevenue), "ether"))); 
      }
      setAddresses4(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot3:", error);
    }
  };  

  const userinfo_Slot6 = async () => {
    try {
      let a;
      let aa ;
      let intex1;
      let aab = 0 ;
      let formattedAddresses = [];
      let totalteam1 = 0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (myState.address) {
        aab = await contract.methods.Users(myState.address).call();
        a = await contract.methods.Users1(myState.address).call();
        let activeid = await contract.methods.check_useractive_id_List(myState.address,6).call();
        for(let w=0 ; w< activeid.length ; w++){
          let team = await contract.methods.User_Referral(parseInt(activeid[w])).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        }
        activeid = parseInt(activeid[parseInt(a.index_slot6)])
        let intex2;
        let intex3;
        if(activeid > 0){
          aa = await contract.methods.Pool_check_Direct(parseInt(activeid)).call();
        formattedAddresses[0] = parseInt(activeid)
         intex1 = parseInt(aa.Id1);
         intex2 = parseInt(aa.Id2);
         intex3 = parseInt(aa.Id3);
        }
        let index1address;
        let index2address;
        let index3address;

        if(intex1 > 0){
          index1address = await contract.methods.Pool_check_Direct(parseInt(intex1)).call();
          formattedAddresses[1] = intex1
          if(parseInt(index1address.Id1) > 0){
            formattedAddresses[4] = parseInt(index1address.Id1)
          }
          if(parseInt(index1address.Id2) > 0){
            formattedAddresses[5] = parseInt(index1address.Id2)
          }
          if(parseInt(index1address.Id3) > 0){
            formattedAddresses[6] = parseInt(index1address.Id3)
          }
        }
        if(intex2 > 0){
          index2address = await contract.methods.Pool_check_Direct(parseInt(intex2)).call();
          formattedAddresses[2] = intex2
          if(parseInt(index2address.Id1) > 0){
            formattedAddresses[7] = parseInt(index2address.Id1)
          }
          if(parseInt(index2address.Id2) > 0){
            formattedAddresses[8] = parseInt(index2address.Id2)
          }
          if(parseInt(index2address.Id3) > 0){
            formattedAddresses[9] = parseInt(index2address.Id3)
          }
        }
        if(intex3 > 0){
          index3address = await contract.methods.Pool_check_Direct(parseInt(intex3)).call();
          formattedAddresses[3] = intex3
          if(parseInt(index3address.Id1) > 0){
            formattedAddresses[10] = parseInt(index3address.Id1)
          }
          if(parseInt(index3address.Id2) > 0){
            formattedAddresses[11] = parseInt(index3address.Id2)
          }
          if(parseInt(index3address.Id3) > 0){
            formattedAddresses[12] = parseInt(index3address.Id3)
          }         
        }                

        setmemberslot6(parseInt(totalteam1))
        let totalrenew = parseInt(parseInt(totalteam1) / 12);
        setrenewslot6(totalrenew);
        dispatch(totalteamsize_slot6(parseInt(totalteam1)));
        dispatch(totalrevnew_slot6(totalrenew)); 
        dispatch(Total_slot_revenue_slot6(window.web3.utils.fromWei(parseInt(aab.Totalslotrevenue), "ether"))); 
      }
      setAddresses5(formattedAddresses);
    } catch (error) {
      console.log("Registration Error slot3:", error);
    }
  };  


  useEffect(() => {
    userinfo();
    userinfo1();
    userinfo_Slot3();
    userinfo_Slot4();
    userinfo_Slot5();
    userinfo_Slot6();
  }, [myState.address]);

  return (
    <div>
      <div className="page-wrapper" id="home">
        <div className="page-content pb-5">
          <div className="row">
            <div className="contentt">
              <span>Phoenix 5X</span>
              <span>{amount} USDT</span>
            </div>
          </div>
          <div className="row pb-1">
            <div className="col-lg-6 col-xl-4 pb-2 ">
              <Link to="/Dashboard/Slot1">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot1</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>5</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  class={
                                    addresses[0]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                ></div>
                              </h2>
                              {/* <span className="kqjdkq">{addresses[0]}</span> */}
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses[1]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">{addresses[1]}</span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses[4]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      <span className="kqjdkq">
                                        {" "}
                                        {/* {addresses[4]} */}
                                      </span>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses[5]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[5]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses[6]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[6]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses[2]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">
                                    {" "}
                                    {addresses[2]}
                                  </span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses[7]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[7]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses[8]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[8]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses[9]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[9]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses[3]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">{addresses[3]}</span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses[10]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[10]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses[11]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[11]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses[12]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[12]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{userteam}</b>
                        </div>
                        <div>
                          {renewslot1}
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-xl-4 pb-2  ">
              <Link to="/Dashboard/Slot2">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot2</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>10</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    addresses1[0]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                ></div>
                              </h2>
                              {/* <span className="kqjdkq">{addresses[13]}</span> */}
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses1[1]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">
                                    {addresses[14]}
                                  </span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses1[4]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[17]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses1[5]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[18]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses1[6]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[19]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses1[2]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">
                                    {addresses[15]}
                                  </span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses1[7]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[20]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses1[8]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[21]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses1[9]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[22]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses1[3]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">
                                    {addresses[16]}
                                  </span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses1[10]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[23]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses1[11]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[24]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses1[12]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[25]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{memberslot2}</b>
                        </div>
                        <div>{renewslot2}
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-xl-4 pb-2  ">
              <Link to="/Dashboard/Slot3">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot3</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>20</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    addresses2[0]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                ></div>
                              </h2>
                              {/* <span className="kqjdkq">{addresses[26]}</span> */}
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses2[1]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">
                                    {addresses[27]}
                                  </span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses2[4]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[30]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses2[5]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[31]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses2[6]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[32]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses2[2]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">
                                    {addresses[28]}
                                  </span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses2[7]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[33]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses2[8]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[34]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses2[9]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[35]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses2[3]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">
                                    {addresses[29]}
                                  </span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses2[10]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[36]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses2[11]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[37]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses2[12]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[38]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{memberslot3}</b>
                        </div>
                        <div>{renewslot3}
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="row pb-1">
            <div className="col-lg-6 col-xl-4 pb-2  ">
              <Link to="/Dashboard/Slot4">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot4</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>40</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    addresses3[0]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                ></div>
                              </h2>
                              {/* <span className="kqjdkq">{addresses[39]}</span> */}
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses3[1]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">
                                    {addresses[40]}
                                  </span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses3[4]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[43]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses3[5]
                                              ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                              : "bg-white-200 h-5 rounded-full w-5"
                                          }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[44]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses3[6]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[45]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses3[2]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">
                                    {addresses[41]}
                                  </span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses3[7]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[46]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses3[8]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[47]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses3[9]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[48]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses3[3]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                  {/* <span className="kqjdkq">
                                    {addresses[42]}
                                  </span> */}
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses3[10]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[49]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses3[11]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[50]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses3[12]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                      {/* <span className="kqjdkq">
                                        {addresses[51]}
                                      </span> */}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{memberslot4}</b>
                        </div>
                        <div>{renewslot4}
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-xl-4 pb-2  ">
              <Link to="/Dashboard/Slot5">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot5</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>80</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    addresses4[0]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                ></div>
                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses4[1]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses4[4]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses4[5]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses4[6]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses4[2]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses4[7]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses4[8]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses4[9]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses4[3]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses4[10]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses4[11]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses4[12]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{memberslot5}</b>
                        </div>
                        <div>{renewslot5}
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-xl-4 pb-2  ">
              <Link to="/Dashboard/Slot6">
                <div className=" pb-5">
                  <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                    <div className="row">
                      <div className="PDev">
                        <p>Slot6</p>
                        <div>
                          <img src={usdt} alt="usdt" className="usdtCoin" />
                          <b>160</b>
                        </div>
                      </div>
                    </div>
                    <div className="TreeImgMian ">
                      <ul class="tree vertical">
                        <li className="kzsxjka">
                          <div class="popover__wrapper">
                            <a>
                              <h2 class="popover__title">
                                <div
                                  _ngcontent-cgm-c25=""
                                  className={
                                    addresses5[0]
                                      ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                      : "bg-white-200 h-5 rounded-full w-5"
                                  }
                                ></div>
                              </h2>
                            </a>
                          </div>
                          <ul>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses5[1]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses5[4]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses5[5]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses5[6]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses5[2]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses5[7]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses5[8]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses5[9]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <div class="popover__wrapper">
                                <a>
                                  <h2 class="popover__title">
                                    <div
                                      _ngcontent-cgm-c25=""
                                      className={
                                        addresses5[3]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                    ></div>
                                  </h2>
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses5[10]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses5[11]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                                <li>
                                  <div class="popover__wrapper">
                                    <a>
                                      <h2 class="popover__title">
                                        <div
                                          _ngcontent-cgm-c25=""
                                          className={
                                            addresses5[12]
                                          ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                          : "bg-white-200 h-5 rounded-full w-5"
                                      }
                                        ></div>
                                      </h2>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="row">
                      <div className="PDev">
                        <div>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{memberslot6}</b>
                        </div>
                        <div>{renewslot6}
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tree_View;
