import React, { useState, useEffect } from "react";
import usdt from "../../assets/usdt.png";
import { Link } from "react-router-dom";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import {
  contractAddress,
  abi,
  X3contract,
  X3abi,
  tokenabi,
  tokencontractAddress
} from "../../connectivity/api";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { GiRecycle } from "react-icons/gi";
import { FaArrowUpLong } from "react-icons/fa6";

const X3Slot2 = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  let Total_slot2_revenue = useSelector((state) => state.Total_slot_revenue_slot2);
  let totalteamsize2 = useSelector((state) => state.totalteamsize1);
  let User_MainID = useSelector((state) => state.MainID); 

  const [renewslot2, setrenewslot2] = useState(0);
  const [addresses, setaddresses] = useState([]);
  const [usertotalrenew, setusertotalrenew] = useState("0");
  const [userteam, setuserteam] = useState(0);
  const [info1, setinfo1] = useState([]);
  const [recycleIDs, setRecycleIDs] = useState([]);

  let userarray = [];
  let z = [];
  const [allcontrol, setallcontrol] = useState(0);


  const notify_Approve = () => toast("Approve Confirm!");
  const notify_Register = () => toast("Upgrade Confirm slot2 X15!");
  const balancenotfound = () => toast("balance not found!");
  const alreadybuy = () => toast("already buy Slot2 pakage X15!");
  const pleasebuy = () => toast("please buy slot1 pakage");
  



  const register_ = async () => {
    try {
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let X3contractaddress = new window.web3.eth.Contract(X3abi, X3contract);
      let tokenContract = new window.web3.eth.Contract(
        tokenabi,
        tokencontractAddress
      );
      let ab = await contract.methods.Users(myState.address).call();
      let a = await X3contractaddress.methods.Users_3X(myState.address).call();
      let balanceOf = await tokenContract.methods
        .balanceOf(myState.address)
        .call();
      let oneEtherInWei = window.web3.utils.toWei(10, "ether");
      // a = await contract.methods.Users1(myState.address).call();
      if(ab.firstid > 0){
      if (a.firstid_slot2 == 0) {
        if (parseInt(balanceOf) > oneEtherInWei) {
          await tokenContract.methods
            .approve(X3contract, oneEtherInWei)
            .send({ from: myState.address });
          notify_Approve();
          console.log("myState.address:", myState.address);
          console.log("123:", oneEtherInWei);
          await X3contractaddress.methods
            .upgrade(oneEtherInWei)
            .send({ from: myState.address });
          notify_Register();
        } else {
          balancenotfound();
        }
      } else {
        alreadybuy();
      }
    }else{
      pleasebuy();
    }
    } catch (error) {
      console.log("Registration Error:", error);
    }
  };

  const userinfo_slot2 = async (wwww) => {
    try {
      let intex1;
      let a;
      let aa ;
      let formattedAddresses = [];
      let totalteam1 =0;
      let user_ref = [];
      let line1 = 0; 
      let line2 = 0; 
      let line3 = 0; 
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let X_3contract = new window.web3.eth.Contract(X3abi, X3contract);
      z = await X_3contract.methods.showDataContract().call(); 
      if (myState.address) { 
        a = await X_3contract.methods.Users_3X(myState.address).call();
        let activeid =wwww // await X_3contract.methods.check_useractive_id_List(myState.address,2).call();
        // for(let w=0 ; w< activeid.length ; w++){
          let team = await X_3contract.methods.User_Referral(parseInt(activeid)).call();
          totalteam1 = parseInt(totalteam1) + parseInt(team.referrals)
        // }
        console.log("8" , activeid)
        aa = await X_3contract.methods.Pool_check_Direct(parseInt(activeid)).call(); 
        formattedAddresses[0] = parseInt(User_MainID.amount)
         intex1 = parseInt(aa.Id1);
        let intex2 = parseInt(aa.Id2);
        let intex3 = parseInt(aa.Id3);
        let index1address;
        let index2address;
        let index3address;

        // if(intex1 > 0){
        //   index1address = await X_3contract.methods.Pool_check_Direct(parseInt(intex1)).call();
        //   formattedAddresses[1] = intex1
        //   user_ref.push(parseInt(intex1))
        //   if(parseInt(index1address.Id1) > 0){
        //     formattedAddresses[4] = parseInt(index1address.Id1)
        //     user_ref.push(parseInt(index1address.Id1))
        //     let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id1)).call();
        //     if( parseInt(q.Id1) > 0 ){
        //       formattedAddresses[13] = parseInt(q.Id1)
        //       user_ref.push(parseInt(q.Id1))
        //     }
        //     if( parseInt(q.Id2) > 0 ){
        //       formattedAddresses[14] = parseInt(q.Id2)
        //       user_ref.push(parseInt(q.Id2))
        //     }
        //     if( parseInt(q.Id3) > 0 ){
        //       formattedAddresses[15] = parseInt(q.Id3)
        //       user_ref.push(parseInt(q.Id3))
        //     }
        //   }
        //   if(parseInt(index1address.Id2) > 0){
        //     formattedAddresses[5] = parseInt(index1address.Id2)
        //     user_ref.push(parseInt(index1address.Id2))
        //     let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id2)).call();
        //     if( parseInt(q.Id1) > 0 ){
        //       formattedAddresses[16] = parseInt(q.Id1)
        //       user_ref.push(parseInt(q.Id1))
        //     }
        //     if( parseInt(q.Id2) > 0 ){
        //       formattedAddresses[17] = parseInt(q.Id2)
        //       user_ref.push(parseInt(q.Id2))
        //     }
        //     if( parseInt(q.Id3) > 0 ){
        //       formattedAddresses[18] = parseInt(q.Id3)
        //       user_ref.push(parseInt(q.Id3))
        //     }            
        //   }
        //   if(parseInt(index1address.Id3) > 0){
        //     formattedAddresses[6] = parseInt(index1address.Id3)
        //     user_ref.push(parseInt(index1address.Id3))
        //     let q = await X_3contract.methods.Pool_check_Direct(parseInt(index1address.Id3)).call();
        //     if( parseInt(q.Id1) > 0 ){
        //       formattedAddresses[19] = parseInt(q.Id1)
        //       user_ref.push(parseInt(q.Id1))
        //     }
        //     if( parseInt(q.Id2) > 0 ){
        //       formattedAddresses[20] = parseInt(q.Id2)
        //       user_ref.push(parseInt(q.Id2))
        //     }
        //     if( parseInt(q.Id3) > 0 ){
        //       formattedAddresses[21] = parseInt(q.Id3)
        //       user_ref.push(parseInt(q.Id3))
        //     }                
        //   }
        // }
        // if(intex2 > 0){
        //   index2address = await X_3contract.methods.Pool_check_Direct(parseInt(intex2)).call();
        //   formattedAddresses[2] = intex2
        //   user_ref.push(parseInt(intex2))
        //   if(parseInt(index2address.Id1) > 0){
        //     formattedAddresses[7] = parseInt(index2address.Id1)
        //     user_ref.push(parseInt(index2address.Id1))
        //     let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id1)).call();
        //     if( parseInt(q.Id1) > 0 ){
        //       formattedAddresses[22] = parseInt(q.Id1)
        //       user_ref.push(parseInt(q.Id1))
        //     }
        //     if( parseInt(q.Id2) > 0 ){
        //       formattedAddresses[23] = parseInt(q.Id2)
        //       user_ref.push(parseInt(q.Id2))
        //     }
        //     if( parseInt(q.Id3) > 0 ){
        //       formattedAddresses[24] = parseInt(q.Id3)
        //       user_ref.push(parseInt(q.Id3))
        //     }               
        //   }
        //   if(parseInt(index2address.Id2) > 0){
        //     formattedAddresses[8] = parseInt(index2address.Id2)
        //     user_ref.push(parseInt(index2address.Id2))
        //     let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id2)).call();
        //     if( parseInt(q.Id1) > 0 ){
        //       formattedAddresses[25] = parseInt(q.Id1)
        //       user_ref.push(parseInt(q.Id1))
        //     }
        //     if( parseInt(q.Id2) > 0 ){
        //       formattedAddresses[26] = parseInt(q.Id2)
        //       user_ref.push(parseInt(q.Id2))
        //     }
        //     if( parseInt(q.Id3) > 0 ){
        //       formattedAddresses[27] = parseInt(q.Id3)
        //       user_ref.push(parseInt(q.Id3))
        //     }             
        //   }
        //   if(parseInt(index2address.Id3) > 0){
        //     formattedAddresses[9] = parseInt(index2address.Id3)
        //     user_ref.push(parseInt(index2address.Id3))
        //     let q = await X_3contract.methods.Pool_check_Direct(parseInt(index2address.Id3)).call();
        //     if( parseInt(q.Id1) > 0 ){
        //       formattedAddresses[28] = parseInt(q.Id1)
        //       user_ref.push(parseInt(q.Id1))
        //     }
        //     if( parseInt(q.Id2) > 0 ){
        //       formattedAddresses[29] = parseInt(q.Id2)
        //       user_ref.push(parseInt(q.Id2))
        //     }
        //     if( parseInt(q.Id3) > 0 ){
        //       formattedAddresses[30] = parseInt(q.Id3)
        //       user_ref.push(parseInt(q.Id3))
        //     }             
        //   }          
        // }
        // if(intex3 > 0){
        //   index3address = await X_3contract.methods.Pool_check_Direct(parseInt(intex3)).call();
        //   formattedAddresses[3] = intex3
        //   user_ref.push(parseInt(intex3))
        //   if(parseInt(index3address.Id1) > 0){
        //     formattedAddresses[10] = parseInt(index3address.Id1)
        //     user_ref.push(parseInt(index3address.Id1))
        //     let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id1)).call();
        //     if( parseInt(q.Id1) > 0 ){
        //       formattedAddresses[31] = parseInt(q.Id1)
        //       user_ref.push(parseInt(q.Id1))
        //     }
        //     if( parseInt(q.Id2) > 0 ){
        //       formattedAddresses[32] = parseInt(q.Id2)
        //       user_ref.push(parseInt(q.Id2))
        //     }
        //     if( parseInt(q.Id3) > 0 ){
        //       formattedAddresses[33] = parseInt(q.Id3)
        //       user_ref.push(parseInt(q.Id3))
        //     }            
        //   }
        //   if(parseInt(index3address.Id2) > 0){
        //     formattedAddresses[11] = parseInt(index3address.Id2)
        //     user_ref.push(parseInt(index3address.Id2))
        //     let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id2)).call();
        //     if( parseInt(q.Id1) > 0 ){
        //       formattedAddresses[34] = parseInt(q.Id1)
        //       user_ref.push(parseInt(q.Id1))
        //     }
        //     if( parseInt(q.Id2) > 0 ){
        //       formattedAddresses[35] = parseInt(q.Id2)
        //       user_ref.push(parseInt(q.Id2))
        //     }
        //     if( parseInt(q.Id3) > 0 ){
        //       formattedAddresses[36] = parseInt(q.Id3)
        //       user_ref.push(parseInt(q.Id3))
        //     }            
        //   }
        //   if(parseInt(index3address.Id3) > 0){
        //     formattedAddresses[12] = parseInt(index3address.Id3)
        //     user_ref.push(parseInt(index3address.Id3))
        //     let q = await X_3contract.methods.Pool_check_Direct(parseInt(index3address.Id3)).call();
        //     if( parseInt(q.Id1) > 0 ){
        //       formattedAddresses[37] = parseInt(q.Id1)
        //       user_ref.push(parseInt(q.Id1))
        //     }
        //     if( parseInt(q.Id2) > 0 ){
        //       formattedAddresses[38] = parseInt(q.Id2)
        //       user_ref.push(parseInt(q.Id2))
        //     }
        //     if( parseInt(q.Id3) > 0 ){
        //       formattedAddresses[39] = parseInt(q.Id3)
        //       user_ref.push(parseInt(q.Id3))
        //     }             
        //   }            
        // }   

        if (intex1 > 0) {
          index1address = await X_3contract.methods
            .Pool_check_Direct(parseInt(intex1))
            .call();
          formattedAddresses[1] = intex1;
          line1++;
          user_ref.push(parseInt(intex1));
          if (parseInt(index1address.Id1) > 0) {
            formattedAddresses[4] = parseInt(index1address.Id1);
            user_ref.push(parseInt(index1address.Id1));
            let q = await X_3contract.methods
              .Pool_check_Direct(parseInt(index1address.Id1))
              .call();
              line2++;
            if (parseInt(q.Id1) > 0) {
              formattedAddresses[13] = parseInt(q.Id1);
              user_ref.push(parseInt(q.Id1));
              line3++;
            }
            if (parseInt(q.Id2) > 0) {
              formattedAddresses[14] = parseInt(q.Id2);
              user_ref.push(parseInt(q.Id2));
              line3++;
            }
            if (parseInt(q.Id3) > 0) {
              formattedAddresses[15] = parseInt(q.Id3);
              user_ref.push(parseInt(q.Id3));
              line3++;
            }
          }
          if (parseInt(index1address.Id2) > 0) {
            formattedAddresses[5] = parseInt(index1address.Id2);
            user_ref.push(parseInt(index1address.Id2));
            let q = await X_3contract.methods
              .Pool_check_Direct(parseInt(index1address.Id2))
              .call();
              line2++;
            if (parseInt(q.Id1) > 0) {
              formattedAddresses[16] = parseInt(q.Id1);
              user_ref.push(parseInt(q.Id1));
              line3++;
            }
            if (parseInt(q.Id2) > 0) {
              formattedAddresses[17] = parseInt(q.Id2);
              user_ref.push(parseInt(q.Id2));
              line3++;
            }
            if (parseInt(q.Id3) > 0) {
              formattedAddresses[18] = parseInt(q.Id3);
              user_ref.push(parseInt(q.Id3));
              line3++;
            }
          }
          if (parseInt(index1address.Id3) > 0) {
            formattedAddresses[6] = parseInt(index1address.Id3);
            user_ref.push(parseInt(index1address.Id3));
            let q = await X_3contract.methods
              .Pool_check_Direct(parseInt(index1address.Id3))
              .call();
              line2++;
            if (parseInt(q.Id1) > 0) {
              formattedAddresses[19] = parseInt(q.Id1);
              user_ref.push(parseInt(q.Id1));
              line3++;
            }
            if (parseInt(q.Id2) > 0) {
              formattedAddresses[20] = parseInt(q.Id2);
              user_ref.push(parseInt(q.Id2));
              line3++;
            }
            if (parseInt(q.Id3) > 0) {
              formattedAddresses[21] = parseInt(q.Id3);
              user_ref.push(parseInt(q.Id3));
              line3++;
            }
          }
        }
        if (intex2 > 0) {
          index2address = await X_3contract.methods
            .Pool_check_Direct(parseInt(intex2))
            .call();
          formattedAddresses[2] = intex2;
          line1++;
          user_ref.push(parseInt(intex2));
          if (parseInt(index2address.Id1) > 0) {
            formattedAddresses[7] = parseInt(index2address.Id1);
            user_ref.push(parseInt(index2address.Id1));
            let q = await X_3contract.methods
              .Pool_check_Direct(parseInt(index2address.Id1))
              .call();
              line2++;
            if (parseInt(q.Id1) > 0) {
              formattedAddresses[22] = parseInt(q.Id1);
              user_ref.push(parseInt(q.Id1));
              line3++;
            }
            if (parseInt(q.Id2) > 0) {
              formattedAddresses[23] = parseInt(q.Id2);
              user_ref.push(parseInt(q.Id2));
              line3++;
            }
            if (parseInt(q.Id3) > 0) {
              formattedAddresses[24] = parseInt(q.Id3);
              user_ref.push(parseInt(q.Id3));
              line3++;
            }
          }
          if (parseInt(index2address.Id2) > 0) {
            formattedAddresses[8] = parseInt(index2address.Id2);
            user_ref.push(parseInt(index2address.Id2));
            let q = await X_3contract.methods
              .Pool_check_Direct(parseInt(index2address.Id2))
              .call();
              line2++;
            if (parseInt(q.Id1) > 0) {
              formattedAddresses[25] = parseInt(q.Id1);
              user_ref.push(parseInt(q.Id1));
              line3++;
            }
            if (parseInt(q.Id2) > 0) {
              formattedAddresses[26] = parseInt(q.Id2);
              user_ref.push(parseInt(q.Id2));
              line3++;
            }
            if (parseInt(q.Id3) > 0) {
              formattedAddresses[27] = parseInt(q.Id3);
              user_ref.push(parseInt(q.Id3));
              line3++;
            }
          }
          if (parseInt(index2address.Id3) > 0) {
            formattedAddresses[9] = parseInt(index2address.Id3);
            formattedAddresses[8] = parseInt(index2address.Id3);
            let q = await X_3contract.methods
              .Pool_check_Direct(parseInt(index2address.Id3))
              .call();
              line2++;
            if (parseInt(q.Id1) > 0) {
              formattedAddresses[28] = parseInt(q.Id1);
              user_ref.push(parseInt(q.Id1));
              line3++;
            }
            if (parseInt(q.Id2) > 0) {
              formattedAddresses[29] = parseInt(q.Id2);
              user_ref.push(parseInt(q.Id2));
              line3++;
            }
            if (parseInt(q.Id3) > 0) {
              formattedAddresses[30] = parseInt(q.Id3);
              user_ref.push(parseInt(q.Id3));
              line3++;
            }
          }
        }
        if (intex3 > 0) {
          index3address = await X_3contract.methods
            .Pool_check_Direct(parseInt(intex3))
            .call();
          formattedAddresses[3] = intex3;
          line1++;
          user_ref.push(parseInt(intex3));
          if (parseInt(index3address.Id1) > 0) {
            formattedAddresses[10] = parseInt(index3address.Id1);
            user_ref.push(parseInt(index3address.Id1));
            let q = await X_3contract.methods
              .Pool_check_Direct(parseInt(index3address.Id1))
              .call();
              line2++;
            if (parseInt(q.Id1) > 0) {
              formattedAddresses[31] = parseInt(q.Id1);
              user_ref.push(parseInt(q.Id1));
              line3++;
            }
            if (parseInt(q.Id2) > 0) {
              formattedAddresses[32] = parseInt(q.Id2);
              user_ref.push(parseInt(q.Id2));
              line3++;
            }
            if (parseInt(q.Id3) > 0) {
              formattedAddresses[33] = parseInt(q.Id3);
              user_ref.push(parseInt(q.Id3));
              line3++;
            }
          }
          if (parseInt(index3address.Id2) > 0) {
            formattedAddresses[11] = parseInt(index3address.Id2);
            user_ref.push(parseInt(index3address.Id2));
            let q = await X_3contract.methods
              .Pool_check_Direct(parseInt(index3address.Id2))
              .call();
              line2++;
            if (parseInt(q.Id1) > 0) {
              formattedAddresses[34] = parseInt(q.Id1);
              user_ref.push(parseInt(q.Id1));
              line3++;
            }
            if (parseInt(q.Id2) > 0) {
              formattedAddresses[35] = parseInt(q.Id2);
              user_ref.push(parseInt(q.Id2));
              line3++;
            }
            if (parseInt(q.Id3) > 0) {
              formattedAddresses[36] = parseInt(q.Id3);
              user_ref.push(parseInt(q.Id3));
              line3++;
            }
          }
          if (parseInt(index3address.Id3) > 0) {
            formattedAddresses[12] = parseInt(index3address.Id3);
            user_ref.push(parseInt(index3address.Id3));
            let q = await X_3contract.methods
              .Pool_check_Direct(parseInt(index3address.Id3))
              .call();
              line2++;
            if (parseInt(q.Id1) > 0) {
              formattedAddresses[37] = parseInt(q.Id1);
              user_ref.push(parseInt(q.Id1));
              line3++;
            }
            if (parseInt(q.Id2) > 0) {
              formattedAddresses[38] = parseInt(q.Id2);
              user_ref.push(parseInt(q.Id2));
              line3++;
            }
            if (parseInt(q.Id3) > 0) {
              formattedAddresses[39] = parseInt(q.Id3);
              user_ref.push(parseInt(q.Id3));
              line3++;
            }
          }
        }

       let totalrenew = parseInt(parseInt(totalteam1)/ 39) ;
       setuserteam(parseInt(totalteam1));
       setrenewslot2(totalrenew);
       let rrr = 0;

      //  if (parseInt(totalteam1) > 12) {
      //    let sa = parseInt(totalteam1) - 12;
      //    rrr += sa * 4.5;
      //    rrr += 9 * 2.70;
      //    rrr += 3 * 1.8;
      //  } else if (parseInt(totalteam1) > 3) {
      //    let sa = parseInt(totalteam1) - 3;
      //    rrr += sa * 2.70;
      //    rrr += 3 * 1.18;
      //  } else {
      //    rrr += parseInt(totalteam1) * 1.18;
      //  }
      if(line1 > 0){
        rrr += line1*1.8;
      }
      if(line2 > 0){
        rrr += line2*2.7;
      }
      if(line3 > 0){
        rrr += line3*4.5;
      }       

       let abc = await X_3contract.methods.showuser_income(wwww).call();
       const filtered = abc  //z.filter(
      //    (entry) =>
      //      user_ref.includes(parseInt(entry.userId)) &&
      //      parseInt(entry.slot) === 2 &&
      //      parseInt(entry.x) === 2
      //  );
       console.log("rrrr...trrrrrr1filtered", abc);
       setinfo1(filtered);
       setusertotalrenew((rrr).toFixed(2));       
      }
      setaddresses(formattedAddresses);  
      
      let tempRecycleIDs = [];
      for (let t = 0; t < formattedAddresses.length; t++) {
        if (parseInt(formattedAddresses[t]) > 0) {
          let g = await X_3contract.methods
            .User_Referral(formattedAddresses[t])
            .call();
          if (parseInt(g.reinvestID) === 1) {
            tempRecycleIDs.push(parseInt(formattedAddresses[t]));
          }
        }
      }
      console.log("234ert", recycleIDs);
      setRecycleIDs(tempRecycleIDs);
    } catch (error) {
      console.log("Registration Error slot2:", error);
    }
  };  

  const indexxxss = async (hgqku) => {
    window.web3 = new Web3(window.ethereum);
      let X3contractaddress = new window.web3.eth.Contract(X3abi, X3contract);
      if(hgqku == 0)
        {
          let a = await X3contractaddress.methods.Users_3X(myState.address).call();
          let indexxessid = await X3contractaddress.methods.check_useractive_id_List(myState.address,2).call();
          userinfo_slot2(indexxessid[parseInt(a.index_slot2)])
        }else{
          setallcontrol(hgqku) ;
          let a = await X3contractaddress.methods.Users_3X(myState.address).call();
          let indexxessid = await X3contractaddress.methods.check_useractive_id_List(myState.address,2).call();
          if(indexxessid.length > 1 ){
            userinfo_slot2(parseInt(indexxessid[parseInt(a.index_slot2) - parseInt(allcontrol)]))
          }else{
            userinfo_slot2(parseInt(indexxessid[parseInt(a.index_slot2)]))
          }
        }
  }

  const indexxxss_next = async () => {
    window.web3 = new Web3(window.ethereum);
    let X3contractaddress = new window.web3.eth.Contract(X3abi, X3contract);
          let a = await X3contractaddress.methods.Users_3X(myState.address).call();
          let indexxessid = await X3contractaddress.methods.check_useractive_id_List(myState.address,2).call();
          userinfo_slot2(parseInt(indexxessid[parseInt(a.index_slot2)]))
  } 




  const formatAmount = (amount) => {
    return (window.web3.utils.fromWei(amount.toString(), 'ether')); // Convert Wei to Ether
  };

  const formatTime = (timestamp) => {
    const date = new Date(Number(timestamp) * 1000); // Convert BigInt to number and then to milliseconds
    return date.toLocaleString();
  };


  const getslotnumber = (amount) => {
    return 2; // Convert Wei to Ether
  };

  useEffect(() => {
    indexxxss(0);
  }, [myState.address]);







  return (
    <div>
      <div className="page-wrapper" id="home">
        <div className="page-content pb-5">
          <div className="row">
            <div className="contentt">
              <span>Slot 2</span>
            </div>
          </div>
          <div className="row pb-1">
            <div className="pb-2">
              <div className=" pb-3">
                <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                  <div className="row">
                    <div className="PDev">
                      <p>Slot2</p>
                      <div>
                        <img src={usdt} alt="usdt" className="usdtCoin" />
                        <b>10</b>
                      </div>
                    </div>
                  </div>
                  <div className="TreeImgMian ">
                    <ul class="tree vertical">
                      <li className="kzsxjka">
                        <div class="popover__wrapper">
                          <a>
                            <h2 class="popover__title">
                              <div
                                _ngcontent-cgm-c25=""
                                class={
                                  addresses[0]
                                    ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                    : "bg-white-200 h-5 rounded-full w-5"
                                }
                              >
                                {addresses[0]}
                              </div>
                              {addresses[0] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[0])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                            </h2>
                          </a>
                        </div>
                        <ul>
                          <li>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    class={
                                      addresses[1]
                                        ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                        : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >
                                    {addresses[1]}
                                  </div>
                                  {addresses[1] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[1])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[4]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >{addresses[4]}</div>
                                      {addresses[4] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[4])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[13]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[13]}</div>
                                          {addresses[13] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[13])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[14]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[14]}</div>
                                          {addresses[14] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[14])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[15]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[15]}</div>
                                          {addresses[15] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[15])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[5]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >{addresses[5]}</div>
                                      {addresses[5] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[5])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[16]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[16]}</div>
                                          {addresses[16] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[16])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[17]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[17]}</div>
                                          {addresses[17] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[17])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[18]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[18]}</div>
                                          {addresses[18] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[18])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[6]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >{addresses[6]}</div>
                                      {addresses[6] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[6])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[19]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[19]}</div>
                                          {addresses[19] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[19])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[20]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[20]}</div>
                                          {addresses[20] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[20])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[21]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[21]}</div>
                                          {addresses[21] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[21])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    class={
                                      addresses[2]
                                        ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                        : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >{addresses[2]}</div>
                                  {addresses[2] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[2])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[7]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >{addresses[7]}</div>
                                      {addresses[7] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[7])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[22]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[22]}</div>
                                          {addresses[22] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[22])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[23]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[23]}</div>
                                          {addresses[23] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[23])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[24]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[24]}</div>
                                          {addresses[24] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[24])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[8]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >{addresses[8]}</div>
                                      {addresses[8] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[8])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[25]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[25]}</div>
                                          {addresses[25] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[25])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[26]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[26]}</div>
                                          {addresses[26] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[26])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[27]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[27]}</div>
                                          {addresses[27] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[27])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[9]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >{addresses[9]}</div>
                                      {addresses[9] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[9])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[28]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[28]}</div>
                                          {addresses[28] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[28])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[29]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[29]}</div>
                                          {addresses[29] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[29])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[30]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[30]}</div>
                                          {addresses[30] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[30])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    class={
                                      addresses[3]
                                        ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                        : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >{addresses[3]}</div>
                                  {addresses[3] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[3])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[10]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >{addresses[10]}</div>
                                      {addresses[10] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[10])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[31]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[31]}</div>
                                          {addresses[31] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[31])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[32]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[32]}</div>
                                          {addresses[32] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[32])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[33]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[33]}</div>
                                          {addresses[33] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[33])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[11]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >{addresses[11]}</div>
                                      {addresses[11] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[11])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[34]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[34]}</div>
                                          {addresses[34] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[34])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[35]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[35]}</div>
                                          {addresses[35] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[35])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[36]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[36]}</div>
                                          {addresses[36] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[36])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        class={
                                          addresses[12]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >{addresses[12]}</div>
                                      {addresses[12] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[12])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                    </h2>
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[37]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[37]}</div>
                                          {addresses[37] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[37])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[38]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[38]}</div>
                                          {addresses[38] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[38])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <div
                                            _ngcontent-cgm-c25=""
                                            class={
                                              addresses[39]
                                                ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                                : "bg-white-200 h-5 rounded-full w-5"
                                            }
                                          >{addresses[39]}</div>
                                          {addresses[39] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[39])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                        </h2>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="PDev">
                      <div className="d-flex">
                        <div className="me-4">
                          <p className="mb-0 pb-0">Members</p>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{userteam}</b>
                        </div>
                        <div>
                          <p className="mb-0 pb-0">Cycle</p>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                          <b>{renewslot2}</b>
                        </div>
                      </div>

                      <div>
                        <p className="mb-0 pb-0">Total slot revenue</p>
                        <svg
                          _ngcontent-onl-c25=""
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="#2CFF4E"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 stroke-current text-white-500"
                        >
                          <path
                            _ngcontent-onl-c25=""
                            clip-rule="evenodd"
                            d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                          ></path>
                        </svg>
                        <b>{usertotalrenew} USDT</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="row akhriPutti lksnflf">
            <div className="kdsjflke">
              <Link to="/Dashboard/X3Slot1">
                <span>
                  <GrLinkPrevious /> Prev
                </span>
              </Link>
              <span>Slot: 2</span>
              <Link to="/Dashboard/X3Slot3">
                <span>
                  Next <GrLinkNext />
                </span>
              </Link>
            </div>
          </div>
          <div className="row akhriPutti lksnflf mt-4">
            <div className="kdsjflke">
              <Link>
                <span onClick={() => indexxxss(1)}>
                  <GrLinkPrevious /> Prev
                </span>  
              </Link>
              <span>Recycle</span>
              <Link >
                <span onClick={() => indexxxss_next()}>
                  Next <GrLinkNext />
                </span>
              </Link>
            </div>
          </div>
          <div className="row akhriPutti mt-4">
            <div className="kdsjflkerre">
              <div
                className="btn btn-primary waves-effect waves-light pre btn-sm"
                onClick={register_}
              >
                Buy Slot
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive lksnflf">
        <table className="table align-middle table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th className="align-middle">S.No</th>
              <th className="align-middle">Amount</th>
              <th className="align-middle">Time</th>
              <th className="align-middle">Slot</th>
              <th className="align-middle">Upline ID</th>
              <th className="align-middle">Matrix ID</th>
              <th className="align-middle">Address</th>
            </tr>
          </thead>
          <tbody>
            {info1.map((user, index) => (
              <tr key={index}>
                <td className="align-middle">{index + 1}</td>
                <td className="align-middle">{formatAmount(user.amount)}</td>
                <td className="align-middle">{formatTime(user.time)}</td>
                <td className="align-middle">
                  {getslotnumber(user.slot.toString())}
                </td>
                <td className="align-middle">{user.uplineID.toString()}</td>
                <td className="align-middle">{user.userId.toString()}</td>
                <td className="align-middle">{user.add}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default X3Slot2;
