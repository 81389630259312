import React, { useEffect, useState } from "react";
import {Link,Route,Routes,} from "react-router-dom";
import Partner from "../Partner/Partner";
import Stats from "../Stats/Stats";
import Tree_View from "../Tree_View/Tree_View";
import X3 from "../X3/X3";
import { useDispatch, useSelector } from "react-redux";
import {contractAddress,abi, loadWeb3 ,X3contract, X3abi, ctocontract, ctoabi} from "../../connectivity/api";
import Web3 from "web3";
import { toast } from "react-toastify";
import Slot1 from "../Slot1/Slot1";
import Slot3 from "../Slot3/Slot3";
import Slot4 from "../Slot4/Slot4";
import Slot5 from "../Slot5/Slot5";
import Slot6 from "../Slot6/Slot6";
import Slot2 from "../Slot2/Slot2";
import {totalteamsize,MainID} from "../../Actions";
import { useraddress ,useraddressinshort} from "../../Actions";
import X3Slot1 from "../X3Slot1/X3Slot1";
import X3Slot2 from "../X3Slot2/X3Slot2";
import X3Slot3 from "../X3Slot3/X3Slot3";
import X3Slot4 from "../X3Slot4/X3Slot4";
import X3Slot5 from "../X3Slot5/X3Slot5";
import X3Slot6 from "../X3Slot6/X3Slot6";
import logo_dark from "../../assets/logo_dark.png";
import wallet from "../../assets/wallet.png";
import avatar_1 from "../../assets/avatar_1.jpg";

const Dashboard = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isDropdownOpen, setDropdownOpen] = useState(true);
  const myStateshort = useSelector((state) => state.Usermatamaskshortaddress);
  const [matamaskaddress, setmatamaskaddress] = useState();
  const dispatch = useDispatch();
  const [wattleaddress, setwattleaddress] = useState('');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
      let realaddress = myStateshort.address;
      setmatamaskaddress(realaddress);
    };

    handleResize(); // Check the size on initial render
    window.addEventListener("resize", handleResize); // Add resize event listener

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup the event listener
    };
  }, []);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setSidebarOpen(false);
    }
  };
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const connectaddress = async() => {
    try {
      let address = await loadWeb3();
      setwattleaddress(address);
      setmatamaskaddress(address.slice(0, 3) + "..." + address.slice(38, 42))
      dispatch(useraddress(address.toString()));
      dispatch(useraddressinshort(address.slice(0, 3) + "..." + address.slice(38, 42)));
    } catch (error) {
      console.log("Error" , error)
    }
  }

  return (
    <div>
      <>
        <header
          id="page-topbar"
          style={{
            backgroundImage:
              "linear-gradient(150deg, #57cd4a 20%, #6f23a7 100%)",
          }}
        >
          <div className="navbar-header">
            <div className="d-flex">
              {/* LOGO */}
              <div className="navbar-brand-box">
                <Link to="/Dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      src={logo_dark}
                      alt=""
                      width="60px"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={logo_dark}
                      alt=""
                      width="60px"
                    />
                  </span>
                </Link>
                <Link to="/Dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      src={logo_dark}
                      alt=""
                      width="60px"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={logo_dark}
                      alt=""
                      width="60px"
                    />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
                onClick={toggleSidebar}
              >
                <i className="fa fa-fw fa-bars" />
              </button>
              {/* App Search*/}
              <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={4499}
                  />
                  <button className="buttonse"> Go </button>
                </div>
              </form>
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ms-2">
                {/* <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="mdi mdi-magnify"></i>
                      </button> */}
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="dropdown bges d-inline-block">
                <button
                  type="button"
                  className="btn header-item1 waves-effect"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src={wallet} width="20px" />
                  <span
                    className=" text-white d-xl-inline-block ms-1"
                    key="t-henry"
                  >
                    {matamaskaddress}
                  </span>
                  <img
                    className="rounded-circle header-profile-user"
                    src={avatar_1}
                    alt="Header Avatar"
                  />
                </button>
                <div className="dropdown-menu  dropdown-menu-end">
                  {/* item*/}
                  <a className="dropdown-item" href="#">
                    <img
                      src="Dashboardassets/images/dashboard.png"
                      width="13px"
                    />{" "}
                    <span key="t-profile"> &nbsp;&nbsp;Dashboard</span>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="bx bx-wallet font-size-16 align-middle me-1" />{" "}
                    <span key="t-my-wallet" onClick={connectaddress}>My Wallet</span>
                  </a>
                  <div className="dropdown-divider" />
                  <a
                    className="dropdown-item d-flex justify-content-between text-danger"
                    href="#"
                  >
                    <span key="t-logout" className="text-black">
                      {matamaskaddress}
                    </span>
                    <img src="Dashboardassets/images/copy.png" width="20px" />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* ========== Left Sidebar Start ========== */}
        <div className={`vertical-menu ${isSidebarOpen ? "open" : "closed"}`}>
          <div data-simplebar="" className="h-100">
            {/*- Sidemenu */}
            <div id="sidebar-menu">
              {/* Left Menu Start */}
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menucss">
                  <Link
                    to="/Dashboard"
                    onClick={handleLinkClick}
                    className="waves-effect"
                  >
                    <div className="backs">
                      <i className="bx bx-home-circle" />
                      <span key="t-chat">Dashboard</span>
                    </div>
                  </Link>
                </li>
                <li className="menucss">
                  <a
                    href="javascript: void(0);"
                    className="has-arrow waves-effect"
                    onClick={toggleDropdown}
                  >
                    <i className="fa fa-users" aria-hidden="true" />
                    <span key="t-dashboards">Team</span>
                  </a>
                  <ul
                    className={`sub-menu ${isDropdownOpen ? "open" : "closed"}`}
                    aria-expanded="false"
                  >
                    <li>
                      <Link
                        to="/Dashboard/Partner"
                        key="t-default"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-user" aria-hidden="true" />
                        Patners
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Dashboard/Stats"
                        key="t-saas"
                        onClick={handleLinkClick}
                      >
                        <i className="fa fa-futbol-o" aria-hidden="true" />
                        Stats
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#" className="waves-effect">
                    <i className="bx bx-file" />
                    <span key="t-file-manager">Information</span>
                  </a>
                </li>
                <li>
                  <Link to="/" className="waves-effect">
                    <i className="fa fa-sign-out" aria-hidden="true" />
                    <span key="t-chat">Exit</span>
                  </Link>
                </li>
              </ul>
            </div>
            {/* Sidebar */}
          </div>
        </div>
        {/* Left Sidebar End */}
        {/* ============================================================== */}
        {/* Start right Content here */}
        {/* ============================================================== */}
        <div className={`main-content ${isSidebarOpen ? "open" : "closed"}`}>
          <Routes>
            <Route path="/" element={<DefaultContent />} />
            <Route path="Partner" element={<Partner />} />
            <Route path="Stats" element={<Stats />} />
            <Route path="X2" element={<Tree_View />} />
            <Route path="X3" element={<X3 />} />
            <Route path="/Slot1" element={<Slot1 />} />
            <Route path="/Slot2" element={<Slot2 />} />
            <Route path="/Slot3" element={<Slot3 />} />
            <Route path="/Slot4" element={<Slot4 />} />
            <Route path="/Slot5" element={<Slot5 />} />
            <Route path="/Slot6" element={<Slot6 />} />
            <Route path="/X3Slot1" element={<X3Slot1 />} />
            <Route path="/X3Slot2" element={<X3Slot2 />} />
            <Route path="/X3Slot3" element={<X3Slot3 />} />
            <Route path="/X3Slot4" element={<X3Slot4 />} />
            <Route path="/X3Slot5" element={<X3Slot5 />} />
            <Route path="/X3Slot6" element={<X3Slot6 />} />
          </Routes>
          {/* end main content*/}
        </div>
        {/* END layout-wrapper */}
        {/* Right bar overlay*/}
        <div className="rightbar-overlay" />
      </>
    </div>
  );
};

const DefaultContent = () => {
  const dispatch = useDispatch();
  const myState = useSelector((state) => state.Usermatamaskaddress);
  const myStateshort = useSelector((state) => state.Usermatamaskshortaddress);
  const [matamaskaddressshort, setmatamaskaddressshort] = useState();
  const [X3Amount, setX3Amount] = useState();
  const [MembersTotal, setMembersTotal] = useState("0");
  const [TotalContractEarn, setTotalContractEarn] = useState("0");
  const [ctoincome, setctoincome] = useState(0);
  
  const [Partners, setPartners] = useState("0");
  const [totalteam, settotalteam] = useState("0");
  const [userearn, setuserearn] = useState("0");
  const [userid, setuserid] = useState("0");
  const [userAmount, setuserAmount] = useState("0");
  const [REf_URL, setREf_URL] = useState(
    "https://phoenixmatrix.netlify.app/Register?ref="
  );
  const [star1, setstar1] = useState(0);
  const [star2, setstar2] = useState(0);
  const [star3, setstar3] = useState(0);
  const [star4, setstar4] = useState(0);
  const [star5, setstar5] = useState(0);
  const [star6, setstar6] = useState(0);
  const [star7, setstar7] = useState(0);
  const [star8, setstar8] = useState(0);
  const [star9, setstar9] = useState(0);
  const [star10, setstar10] = useState(0);
  const [star11, setstar11] = useState(0);

  const setCopySuccess = () => toast("Text copied successfully");

  const userinfo = async () => {
    try {
      let team =0;
      let t =0;
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      let CTOcontract = new window.web3.eth.Contract(ctoabi, ctocontract);
      let X3__contract = new window.web3.eth.Contract(X3abi, X3contract);
      let a = await contract.methods.Users(myState.address).call();
      let _X3a = await X3__contract.methods.Users_3X(myState.address).call();
      let ctoincome =await CTOcontract.methods.ctoAmount(myState.address).call();
      setctoincome((parseFloat(window.web3.utils.fromWei((ctoincome), "ether"))).toFixed(2));
      let ids = await contract.methods.ids().call();
      let totalAmount = await contract.methods.totalAmount().call();
      let activeids = await contract.methods.check_useractive_id_List(myState.address,1).call();
      for(let w=0 ; w< activeids.length ; w++){
        let totalteam1 = await contract.methods.User_Referral(parseInt(activeids[w])).call();
        team = parseInt(team) + parseInt(totalteam1.referrals)
        t = parseInt(t) + parseInt(totalteam1.referrals)
      }
      let xxx = await contract.methods.User_Referral(parseInt(a.mainId)).call();    
      let Earn = parseInt(a.Earn);
      let firstid = parseInt(a.mainId);
      let amount = (window.web3.utils.fromWei(parseInt(a.Totalslotrevenue), "ether"))
      let x3a = await contract.methods.Users_3x(myState.address).call();
      let totalAx3 = parseInt(_X3a.Earn) + parseInt(x3a.Earn)
      setX3Amount(window.web3.utils.fromWei(parseInt(totalAx3), "ether"))
      settotalteam(parseInt(team))
      dispatch(totalteamsize(parseInt(t)));
      dispatch(MainID(parseInt(a.mainId)));
      
      setuserid(parseInt(firstid));
      setuserearn((parseFloat(window.web3.utils.fromWei((Earn + parseInt(totalAx3)), "ether"))).toFixed(2));
      setPartners(parseInt(xxx.referrals));
      setMembersTotal(parseInt(ids));
      setuserAmount((parseFloat(window.web3.utils.fromWei((Earn), "ether"))).toFixed(2));
      setTotalContractEarn(window.web3.utils.fromWei(parseInt(totalAmount), "ether"));
      const baseUrl = window.location.origin;
      const url = `${baseUrl}/register?ref=${myState.address}`;
      setREf_URL(url);

      let ata = await contract.methods.Users1(myState.address).call();
      let ata_X3a = await X3__contract.methods.Users_3X1(myState.address).call();
      if(parseInt(a.mainId) > 0){
        setstar1(1);
      }
      if(parseInt(a.mainId_slot2) > 0){
        setstar2(1);
      }
      if(parseInt(ata.mainId_slot3) > 0){
        setstar3(1);
      }
      if(parseInt(ata.mainId_slot4) > 0){
        setstar4(1);
      }
      if(parseInt(ata.mainId_slot5) > 0){
        setstar5(1);
      }
      if(parseInt(ata.mainId_slot6) > 0){
        setstar6(1);
      } 

      if(parseInt(_X3a.mainId_slot2) > 0){
        setstar7(1);
      }
      if(parseInt(ata_X3a.mainId_slot3) > 0){
        setstar8(1);
      }
      if(parseInt(ata_X3a.mainId_slot4) > 0){
        setstar9(1);
      }
      if(parseInt(ata_X3a.mainId_slot5) > 0){
        setstar10(1);
      }
      if(parseInt(ata_X3a.mainId_slot6) > 0){
        setstar11(1);
      } 
      
    } catch (error) {
      console.log("dashboard Error:", error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(REf_URL).then(() => {
      setCopySuccess("Text copied successfully!");
    });
  };

  useEffect(() => {
    userinfo();
  }, []); // Only re-run the effect if count changes

  return (
    <div className="page-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="newsw">
                <div className="">
                  <img
                    src={avatar_1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <div className="adse">
                  <h2>ID {userid}</h2>
                  <a
                    className="dropdown-item d-flex justify-content-between text-danger"
                    href="#"
                  >
                    <span key="t-logout" className="text-black">
                      {myStateshort.address}
                    </span>{" "}
                    &nbsp; &nbsp;
                    <img
                      src="Dashboardassets/images/copy.png"
                      width="20px"
                    />{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="linkbg">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="text-muted">Personal link</h5>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="d-md-block with text-primary h5  font-weight-bold">
                    <input
                      type="text"
                      id="fname"
                      className="inputes"
                      defaultValue={REf_URL}
                      name="fname"
                    />
                  </span>
                  <button
                    className="btn coped btn-primary"
                    onClick={copyToClipboard}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="mb-2 text-muted fw-medium">Partners</p>
                          <h4 className="mb-0">{Partners}</h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon ksfkw avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="fa fa-user font-size-24" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card mini-stats-wid">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="mb-2 text-muted fw-medium">Team</p>
                          <h4 className="mb-0">{totalteam}</h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                          <div className="mini-stat-icon ksfkwT avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title">
                              <i className="fa fa-users font-size-24" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="flex-grow-1">
                      <p className="mb-2 text-muted fw-medium">Profits</p>
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h4 className="mb-2">{userearn} USDT</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="flex-grow-1">
                      <p className="mb-2 text-muted fw-medium">CTO Income</p>
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h4 className="mb-2">{ctoincome} USDT</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-12">
                <div className="widthhhVal">
                  <h2 className="d-flex mt-3">
                    Phoenix Programs &nbsp;
                    <button className="dss d-flex justify-content-between">
                      <i
                        className="fa fa-question-circle fotes"
                        aria-hidden="true"
                      />
                      <span className="text-base sm:text-sm fontr">
                        {" "}
                        &nbsp;Info{" "}
                      </span>
                    </button>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="widthhhVal">
                  <div className="card mini-stats-wid">
                    <div className="card-body mb-4">
                      <div className="d-flex justify-content-between mb-4 mt-4">
                        <div className="">
                          <h4 className="mb-2">Phoenix 5X</h4>
                        </div>
                        <div className="">
                          <h4 className="mb-2">{userAmount}USDT</h4>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <div className="d-flex mb-2">
                            <div className={star1 === 1 ? "bgw" : "bgw1"} />
                            <div className={star2 === 1 ? "bgw" : "bgw1"} />
                            <div className={star3 === 1 ? "bgw" : "bgw1"} />
                          </div>
                          <div className="d-flex mb-2">
                            <div className={star4 === 1 ? "bgw" : "bgw1"} />
                            <div className={star5 === 1 ? "bgw" : "bgw1"} />
                            <div className={star6 === 1 ? "bgw" : "bgw1"} />
                          </div>
                        </div>
                        <div className="mt-10">
                          <Link
                            to="/Dashboard/X2"
                            className="btn btn-primary waves-effect waves-light pre btn-sm"
                          >
                            Preview <i className="mdi mdi-arrow-right ms-1" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="widthhhVal">
                  <div className="card mini-stats-wid">
                    <div className="card-body mb-4">
                      <div className="d-flex justify-content-between mb-4 mt-4">
                        <div className="">
                          <h4 className="mb-2">Phoenix 15X</h4>
                        </div>
                        <div className="">
                          <h4 className="mb-2">{X3Amount} USDT</h4>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <div className="d-flex mb-2">
                            <div className={star1 === 1 ? "bgw" : "bgw1"} />
                            <div className={star7 === 1 ? "bgw" : "bgw1"} />
                            <div className={star8 === 1 ? "bgw" : "bgw1"} />
                          </div>
                          <div className="d-flex mb-2">
                            <div className={star9 === 1 ? "bgw" : "bgw1"} />
                            <div className={star10 === 1 ? "bgw" : "bgw1"} />
                            <div className={star11 === 1 ? "bgw" : "bgw1"} />
                          </div>
                        </div>
                        <div className="mt-10">
                          <Link
                            to="/Dashboard/X3"
                            className="btn btn-primary waves-effect waves-light pre btn-sm"
                          >
                            Preview <i className="mdi mdi-arrow-right ms-1" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
            <div className="row">
              <div className="col-xl-6">
                <div className="widthhhVal">
                  <div className="card mini-stats-wid bcek">
                    <div className="card-body mb-4">
                      <h4 className="mb-2 mt-4 text-white">
                        X10 phoenix Neural AI Unlimited Binary Matrix -
                        Unlimited phoenix Earnings!
                      </h4>
                      <h4 className="mb-2 mt-11 text-white">Coming Soon</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="widthhhVal">
                  <div className="card">
                    <div className="card-body mt-4">
                      <h4 className="card-title mb-2">
                        Members Total{" "}
                        <i
                          className="fa fa-question-circle fotes"
                          aria-hidden="true"
                        />
                      </h4>
                      <h3 className="mb-4">{MembersTotal}</h3>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body mt-4">
                      <h4 className="card-title mb-2">
                        Members received
                        <i
                          className="fa fa-question-circle fotes"
                          aria-hidden="true"
                        />
                      </h4>
                      <h3 className="mb-4">{TotalContractEarn} USDT</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">© Phoenixmatrix.live.</div>
              {/*  <div class="col-sm-6">
                          <div class="text-sm-end d-none d-sm-block">
                              Design by Phoenixmatrix.live
                          </div>
                      </div> */}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Dashboard;
