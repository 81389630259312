import React, { useState, useEffect } from "react";
import usdt from "../../assets/usdt.png";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";
import Web3 from "web3";
import { contractAddress, abi } from "../../connectivity/api";
import { useSelector } from "react-redux";
import { GiRecycle } from "react-icons/gi";
import { FaArrowUpLong } from "react-icons/fa6";

const Slot1 = () => {
  const myState = useSelector((state) => state.Usermatamaskaddress);
  let myteam = useSelector((state) => state.totalteamsize);
  let thisslotincome = useSelector((state) => state.totalrevnew);
  let User_MainID = useSelector((state) => state.MainID); 
  const [userteam, setuserteam] = useState(0);
  const [renewslot1, setrenewslot1] = useState(0);
  const [addresses, setAddresses] = useState([]);
  const [usertotalrenew, setusertotalrenew] = useState("0");
  const [info1, setinfo1] = useState([]);
  const [allcontrol, setallcontrol] = useState(0);
  let z = [];
  const [recycleIDs, setRecycleIDs] = useState([]);
  // let recycleIDs = []

  let intex1;

  const userinfo = async (wwwww) => {
    try {
      let a;
      let aa;
      let formattedAddresses = [];
      let user_ref = [];
      window.web3 = new Web3(window.ethereum);
      let contract = new window.web3.eth.Contract(abi, contractAddress);
      if (myState.address) {
        z = [];

        a = await contract.methods.Users(myState.address).call();
        z = await contract.methods.showDataContract().call();

        setuserteam(parseInt(myteam.teamsize));
        let activeid = wwwww; //await contract.methods.check_useractive_id_List(myState.address,1).call();
        // activeid = parseInt(activeid[parseInt(a.index)])
        aa = await contract.methods
          .Pool_check_Direct(parseInt(activeid))
          .call();
        formattedAddresses[0] = parseInt(User_MainID.amount);
        intex1 = parseInt(aa.Id1);
        let intex2 = parseInt(aa.Id2);
        let intex3 = parseInt(aa.Id3);
        let index1address;
        let index2address;
        let index3address;
        if (intex1 > 0) {
          index1address = await contract.methods
            .Pool_check_Direct(parseInt(intex1))
            .call();
          user_ref.push(parseInt(intex1));
          formattedAddresses[1] = parseInt(intex1);
          if (parseInt(index1address.Id1) > 0) {
            formattedAddresses[4] = parseInt(index1address.Id1);
            user_ref.push(parseInt(index1address.Id1));
          }
          if (parseInt(index1address.Id2) > 0) {
            formattedAddresses[5] = parseInt(index1address.Id2);
            user_ref.push(parseInt(index1address.Id2));
          }
          if (parseInt(index1address.Id3) > 0) {
            formattedAddresses[6] = parseInt(index1address.Id3);
            user_ref.push(parseInt(index1address.Id3));
          }
        }
        if (intex2 > 0) {
          index2address = await contract.methods
            .Pool_check_Direct(parseInt(intex2))
            .call();
          formattedAddresses[2] = parseInt(intex2);
          user_ref.push(parseInt(intex2));
          if (parseInt(index2address.Id1) > 0) {
            formattedAddresses[7] = parseInt(index2address.Id1);
            user_ref.push(parseInt(index2address.Id1));
          }
          if (parseInt(index2address.Id2) > 0) {
            formattedAddresses[8] = parseInt(index2address.Id2);
            user_ref.push(parseInt(index2address.Id2));
          }
          if (parseInt(index2address.Id3) > 0) {
            formattedAddresses[9] = parseInt(index2address.Id3);
            user_ref.push(parseInt(index2address.Id3));
          }
        }
        if (intex3 > 0) {
          index3address = await contract.methods
            .Pool_check_Direct(parseInt(intex3))
            .call();
          formattedAddresses[3] = parseInt(intex3);
          user_ref.push(parseInt(intex3));
          if (parseInt(index3address.Id1) > 0) {
            formattedAddresses[10] = parseInt(index3address.Id1);
            user_ref.push(parseInt(index3address.Id1));
          }
          if (parseInt(index3address.Id2) > 0) {
            formattedAddresses[11] = parseInt(index3address.Id2);
            user_ref.push(parseInt(index3address.Id2));
          }
          if (parseInt(index3address.Id3) > 0) {
            formattedAddresses[12] = parseInt(index3address.Id3);
            user_ref.push(parseInt(index3address.Id3));
          }
        }
        let totalrenew = parseInt(parseInt(myteam.teamsize) / 12);
        setrenewslot1(totalrenew);
      }
      setAddresses(formattedAddresses);

      let q;
      // const filtered = z.filter(entry => user_ref.includes(parseInt(entry.userId)));
      const filtered = z.filter(
        (entry) =>
          user_ref.includes(parseInt(entry.userId)) &&
          parseInt(entry.slot) == 1 &&
          parseInt(entry.x) == 2
      );
    //   const filtered = z.filter((entry) => {
    //     console.log(`userId: ${entry.userId}, slot: ${entry.slot}, x: ${entry.x}`);
    //     return user_ref.includes(parseInt(entry.userId)) &&
    //            parseInt(entry.slot) === 1 &&
    //            parseInt(entry.x) === 2;
    // });



      console.log("123123" , filtered)
      if (parseInt(parseInt(myteam.teamsize) / 12) == 1) {
        let e = parseInt(parseInt(myteam.teamsize) / 12) * 2;
        q = (parseInt(myteam.teamsize) - e) * 2.25;
      } else {
        if(parseInt(myteam.teamsize) == 11){
          q = 10 * 2.25;
        }else{
          q = parseInt(myteam.teamsize) * 2.25;
        }
        
      }

      let tempRecycleIDs = [];
      for (let t = 0; t < formattedAddresses.length; t++) {
        if (parseInt(formattedAddresses[t]) > 0) {
          let g = await contract.methods
            .User_Referral(formattedAddresses[t])
            .call();
          if (parseInt(g.reinvestID) === 1) {
            tempRecycleIDs.push(parseInt(formattedAddresses[t]));
          }
        }
      }
      setRecycleIDs(tempRecycleIDs);

      setinfo1(filtered);
      setusertotalrenew(q);
    } catch (error) {
      console.log("Registration Error:", error);
    }
  };

  const indexxxss = async (hgqku) => {
    window.web3 = new Web3(window.ethereum);
    let contract = new window.web3.eth.Contract(abi, contractAddress);
    if (hgqku == 0) {
      let a = await contract.methods.Users(myState.address).call();
      let indexxessid = await contract.methods
        .check_useractive_id_List(myState.address, 1)
        .call();
      userinfo(indexxessid[parseInt(a.index)]);
    } else {
      setallcontrol(hgqku);
      let a = await contract.methods.Users(myState.address).call();
      let indexxessid = await contract.methods
        .check_useractive_id_List(myState.address, 1)
        .call();
      if (indexxessid.length > 1) {
        userinfo(
          parseInt(indexxessid[parseInt(a.index) - parseInt(allcontrol)])
        );
      } else {
        userinfo(parseInt(indexxessid[parseInt(a.index)]));
      }
    }
  };

  const indexxxss_next = async () => {
    window.web3 = new Web3(window.ethereum);
    let contract = new window.web3.eth.Contract(abi, contractAddress);
    let a = await contract.methods.Users(myState.address).call();
    let indexxessid = await contract.methods
      .check_useractive_id_List(myState.address, 1)
      .call();
    userinfo(parseInt(indexxessid[parseInt(a.index)]));
  };

  useEffect(() => {
    indexxxss(0);
  }, [myState.address]);

  const formatAmount = (amount) => {
    return 2.25; // Convert Wei to Ether
  };

  const formatTime = (timestamp) => {
    const date = new Date(Number(timestamp) * 1000); // Convert BigInt to number and then to milliseconds
    return date.toLocaleString();
  };

  return (
    <div>
      <div className="page-wrapper" id="home">
        <div className="page-content pb-5">
          <div className="row">
            <div className="contentt">
              <span>Slot 1</span>
            </div>
          </div>
          <div className="row pb-1">
            <div className="pb-2">
              <div className=" pb-3">
                <div className="MintingHistory_Main lksnflf Mobile_MintingHistory_Main">
                  <div className="row">
                    <div className="PDev">
                      <p>Slot1</p>
                      <div>
                        <img src={usdt} alt="usdt" className="usdtCoin" />
                        <b>5</b>
                      </div>
                    </div>
                  </div>
                  <div className="TreeImgMian ">
                    <ul class="tree vertical">
                      <li className="kzsxjka">
                        <div className="popover__wrapper">
                          <a>
                            <h2 className="popover__title">
                              <div
                                className={
                                  recycleIDs.includes(parseInt(addresses[0]))
                                    ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                    : "bg-white-200 h-5 rounded-full w-5"
                                }
                              >
                                {addresses[0]}
                              </div>
                              {addresses[0] ? (
                                recycleIDs.includes(parseInt(addresses[0])) ? (
                                  <div className="bg-main-blue rounded-full w-5 h-5">
                                    <GiRecycle className="recycllee" />
                                  </div>
                                ) : (
                                  <div className="bg-main-blue rounded-full w-5 h-5">
                                    <FaArrowUpLong className="recycllee" />
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </h2>
                          </a>
                        </div>
                        <ul>
                          <li>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    className={
                                      addresses[1]
                                        ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                        : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >
                                    {addresses[1]}
                                  </div>
                                  {addresses[1] ? (
                                    recycleIDs.includes(
                                      parseInt(addresses[1])
                                    ) ? (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <GiRecycle className="recycllee" />
                                      </div>
                                    ) : (
                                      <div className="bg-main-blue rounded-full w-5 h-5">
                                        <FaArrowUpLong className="recycllee" />
                                      </div>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          addresses[4]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[4]}
                                      </div>
                                      {addresses[4] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[4])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          addresses[5]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[5]}
                                      </div>
                                      {addresses[5] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[5])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          addresses[6]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[6]}
                                      </div>
                                      {addresses[6] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[6])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    className={
                                      addresses[2]
                                        ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                        : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >
                                    {addresses[2]}
                                  </div>
                                  {addresses[2] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[2])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          addresses[7]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[7]}
                                      </div>
                                      {addresses[7] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[7])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          addresses[8]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[8]}
                                      </div>
                                      {addresses[8] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[8])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          addresses[9]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[9]}
                                      </div>
                                      {addresses[9] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[9])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <div
                                    _ngcontent-cgm-c25=""
                                    className={
                                      addresses[3]
                                        ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                        : "bg-white-200 h-5 rounded-full w-5"
                                    }
                                  >
                                    {addresses[3]}
                                  </div>
                                  {addresses[3] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[3])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                </h2>
                              </a>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          addresses[10]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[10]}
                                      </div>
                                      {addresses[10] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[10])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          addresses[11]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[11]}
                                      </div>
                                      {addresses[11] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[11])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <div
                                        _ngcontent-cgm-c25=""
                                        className={
                                          addresses[12]
                                            ? "bg-main-blue rounded-full w-7.5 h-7.5"
                                            : "bg-white-200 h-5 rounded-full w-5"
                                        }
                                      >
                                        {addresses[12]}
                                      </div>
                                      {addresses[12] ? (
                                        recycleIDs.includes(
                                          parseInt(addresses[12])
                                        ) ? (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <GiRecycle className="recycllee" />
                                          </div>
                                        ) : (
                                          <div className="bg-main-blue rounded-full w-5 h-5">
                                            <FaArrowUpLong className="recycllee" />
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h2>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="PDev">
                      <div className="d-flex">
                        <div className="me-4">
                          <p className="mb-0 pb-0">Members</p>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="#0000"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567"
                              stroke-width="1.333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <b>{userteam}</b>
                        </div>
                        <div>
                          <p className="mb-0 pb-0">Cycle</p>
                          <svg
                            _ngcontent-onl-c25=""
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="#2CFF4E"
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-5 h-5 stroke-current text-white-500"
                          >
                            <path
                              _ngcontent-onl-c25=""
                              clip-rule="evenodd"
                              d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                            ></path>
                          </svg>
                          <b>{renewslot1}</b>
                        </div>
                      </div>

                      <div>
                        <p className="mb-0 pb-0">Total slot revenue</p>
                        <svg
                          _ngcontent-onl-c25=""
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="#2CFF4E"
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 stroke-current text-white-500"
                        >
                          <path
                            _ngcontent-onl-c25=""
                            clip-rule="evenodd"
                            d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"
                          ></path>
                        </svg>
                        <b>{usertotalrenew} USDT</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row akhriPutti lksnflf">
            <div className="kdsjflke">
              <Link to="/Dashboard/X2">
                <span>
                  <GrLinkPrevious /> Prev
                </span>
              </Link>
              <span>Slot: 1</span>
              <Link to="/Dashboard/Slot2">
                <span>
                  Next <GrLinkNext />
                </span>
              </Link>
            </div>
          </div>
          <div className="row akhriPutti lksnflf mt-4">
            <div className="kdsjflke">
              <span onClick={() => indexxxss(1)}>
                <GrLinkPrevious /> Prev
              </span>
              <span>Recycle</span>
              {/* <Link to="/Dashboard/Slot2"> */}
              <span onClick={() => indexxxss_next(1)}>
                Next <GrLinkNext />
              </span>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive lksnflf">
        <table className="table align-middle table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th className="align-middle">S.No</th>
              <th className="align-middle">Amount</th>
              <th className="align-middle">Time</th>
              <th className="align-middle">Slot</th>
              <th className="align-middle">Upline ID</th>
              <th className="align-middle">Matrix ID</th>
              <th className="align-middle">Address</th>
            </tr>
          </thead>
          <tbody>
            {info1.map((user, index) => (
              <tr key={index}>
                <td className="align-middle">{index + 1}</td>
                <td className="align-middle">{formatAmount(user.amount)}</td>
                <td className="align-middle">{formatTime(user.time)}</td>
                <td className="align-middle">{user.slot.toString()}</td>
                <td className="align-middle">{user.uplineID.toString()}</td>
                <td className="align-middle">{user.userId.toString()}</td>
                <td className="align-middle">{user.add}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Slot1;
